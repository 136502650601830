import React, { useRef } from "react";
import { navigate } from "gatsby";
import { PrivacyPolicy, Layout } from "@components";
import { useDimensions } from "@common";
import * as styles from "./privacy-policy.module.css";

const PrivacyPolicyPage = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const dimensions = useDimensions();
  const { screenSize } = dimensions;

  const relativeStyles = {
    LARGE: { padding: 96, marginLeft: 30, navbarFontSize: 16 },
    MEDIUM: { padding: 70, marginLeft: 20, navbarFontSize: 16 },
    SMALL: { padding: 36, marginLeft: 1, navbarFontSize: 12 },
    EXTRA_SMALL: { padding: 24, marginLeft: 1, navbarFontSize: 12 },
  }[screenSize];

  const pageStyles = {
    contentVisibility: "auto",
    color: "#232129",
    padding: relativeStyles.padding,
    marginTop: 50,
    fontFamily: "Bai Jamjuree, sans-serif",
  };

  const pageReferences = {
    PRIVACY_POLICY: useRef(),
    CONTACT: useRef(),
  };

  const scrollToNode = (node) => {
    node.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollFunctions = {
    scrollToPrivacyPolicy: () => scrollToNode(pageReferences.PRIVACY_POLICY),
    scrollToContact: () => scrollToNode(pageReferences.CONTACT),
  };

  const { scrollToPrivacyPolicy, scrollToContact } = scrollFunctions;

  const navigationOptions = [
    { sectionStringCode: "main-page", scrollFunction: () => navigate("/") },
    {
      sectionStringCode: "privacy-policy",
      scrollFunction: scrollToPrivacyPolicy,
    },
    { sectionStringCode: "contact", scrollFunction: scrollToContact },
  ];

  return (
    <Layout
      navigationOptions={navigationOptions}
      pageReferences={pageReferences}
    >
      <main style={pageStyles}>
        <PrivacyPolicy parentRef={pageReferences.PRIVACY_POLICY} />
      </main>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const Head = () => (
  <title>
    Polityka prywatności | Wycena nieruchomości - Magdalena Panasiuk
  </title>
);
